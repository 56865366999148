/**=====================
    24. According CSS start
==========================**/
.default-according{

    .card{
        margin-bottom:0;
        box-shadow: none !important;
        &+.card{
            margin-top: $according-card-top-margin;
        }
        .btn-link{
            font-weight: $according-btn-weight;
            color: $theme-body-font-color;
            font-size: 16px;
            text-decoration: none;
        }
        .btn-link{
            &:focus,&:hover{
                text-decoration: none;
            }
        }
        .card-header{
            padding: $according-card-header-padding;            
            i{
                position: initial;
                font-size: 20px;
                display: inline-block;
                vertical-align: text-bottom;
                margin-right: 5px;
            }
            h5{
                margin-top: 2px;
            }
        }
        .card-body {           
            border-top:none !important;
            padding: 20px;
            h5{
                font-size: 14px;
                margin-bottom: 5px;
                line-height: 1.5;
            }
            h6{
               color: $light-text ;
               font-size: 12px ;
               margin-bottom: 5px ;
            }
            ul{
                &.rating{
                    li{
                        display: inline-block;
                    }
                }
            }
        }
    }
}
.default-according.style-1{
    button{
        width: 100%;
        text-align: left;
        &:before{
            right: 20px;
            position: absolute;
            transition: 0.4s;
        }
        &[aria-expanded="true"]{
            &:before{
                content: $according-open-icon;
                font-family: $according-card-header-icon;
            }
        }
        &[aria-expanded="false"]{
            &:before{
                content: $according-close-icon;
                font-family: $according-card-header-icon;
            }
        }
    }
}






/**=====================
     24. According CSS Ends
==========================**/