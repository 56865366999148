/**=====================
     09. Error-page  CSS Start
==========================**/
.error-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
  background-color:$white;
  &.maintenance-bg {
    background-image: url(../images/other-images/maintenance-bg.jpg);
    background-color: rgba(255, 255, 255, 0.6);
    background-blend-mode: overlay;
  }
  .maintenance-icons{
    li{
      i{
        color: $semi-dark;
        font-size: 50px;
        animation: rotate-effect 40s infinite linear reverse;
      }
      &:nth-child(2){
        i{
          font-size: 100px;
          margin-left: 110px;
          margin-top: -25px;
          animation: rotate-effect 40s linear infinite reverse;
        }
      }
      &:nth-child(3){
        i{
          animation: rotate-effect 30s infinite linear;
          font-size: 150px;
          margin-top: -85px;
          margin-right: 120px;
        }
      }
    }
  }
  .maintenance-heading {
    margin-top: 70px;
    .headline {
      font-size: 99px;
      font-weight: 900;
      letter-spacing: 10px;
      color: $primary-color;
      z-index: 2;
      position: relative;
      margin-top: -70px;
    }
  }
  .error-heading {
    margin-top: 115px;
    .headline {
      font-size: 285px;
      font-weight: 700;
      letter-spacing: 1px;
      margin-top: -130px;
    }

  }
  .error{
    margin: 70px 0 100px;
    color:$black;
    .error-title{
      font-size: 9.5rem;
      color:$white;
      text-shadow: 6px 6px 7px rgba(0, 0, 0, 0.32);
    }
    .line-bottom{
      width: 10%;
      height: 5px;
      background-color: $warning-color;
      border: none;
    }
    .c-white{
      color:$white;
    }
    .error-btn{
      text-align: center;
    }
    .padd{
      padding: 10px 30px 10px 30px;
    }
    .btn-back-home{
      background-color:$light-color;
      color:$black;
      &:hover{
        background-color:$warning-color;
        color:$white;
        box-shadow: 0 20px 35px 0 rgba(0, 0, 0, 0.21);
      }
    }
    .btn-adnc-serc{
      background-color:$dark-color;
      color:$white;
      &:hover{
        background-color:$warning-color;
        color:$white;
        box-shadow: 0 20px 35px 0 rgba(0, 0, 0, 0.21);
      }
    }
  }
  .svg-wrraper{
    margin-bottom:35px;      
    svg{
      margin-left: auto;
      margin-right: auto;
      width:auto;
      height:400px;
    }
  }
  h3{
    font-weight:700;
    margin-bottom:20px;
  }
  .sub-content{
    font-size: 18px;
    color: $light-text;
    letter-spacing:1px;
    line-height:35px;
    font-weight:normal;   
    z-index:3;   
    margin-bottom:40px;
  } 
}

@media(max-width:991px){
  .error-wrapper {
    .svg-wrraper {
      svg{
        height:450px;
      }
    }
    .sub-content{
      line-height:25px;
    }
  }
}
@media(max-width:767px){
  .error-wrapper {
    h3{
      margin-bottom:20px;
    }
    .sub-content{
      margin-bottom:30px;
      font-size:16px;
    }
  }
}

@media(max-width:575px){
  .error-wrapper{
    .svg-wrraper{
      margin-bottom: 25px;
      svg{
        height:380px;
      }
    }
    .sub-content{
      font-size:14px;
    }
  }
}

@media(max-width:480px){
  .error-wrapper {
    .svg-wrraper {
      svg{
        height:340px;
      }
    }
  }
}

@media(max-width:420px){
  .error-wrapper {
    .svg-wrraper {
      svg{
        height:260px;
      }
    }
  }
}


/**=====================
     09. Error-page CSS Ends
==========================**/