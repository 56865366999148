/**=====================
     27. Coming Soon CSS Start
==========================**/
.comingsoon-bgimg{
  background: url(../images/other-images/coming-soon-bg.jpg);
  background-position: bottom;
  background-size:cover;
}
.comingsoon {
  display:flex;
  align-items:center;
  justify-content:center;
  min-height:100vh;
  padding:50px 0;
  // background-color:$white;
  .comingsoon-inner {
    h5 {
      font-size: 35px;
      letter-spacing: 1px;
      color: $theme-body-sub-title-color;
      font-weight: 900;
      margin-bottom: 20px;
      margin-top: 30px;          
      font-style: italic;
      text-transform: capitalize;
      span{
        // display: block;
        font-weight: 900;
      }
    }
    .countdown {
      padding: 30px 0;
      // border-top:1px solid $light-color;
      // border-bottom:1px solid $light-color;
      border-top: 1px solid rgba($primary-color , 0.2);
      border-bottom: 1px solid rgba($primary-color , 0.2);
      .time {
        display:flex;
        justify-content: center;
        align-items: center;
        border-radius:5px;
        color: $white;
        font-weight: 500;
        width: 100px;
        height: 100px;
        font-size: 36px;
        background: $primary-color;
      }
      .title {
        padding-top:13px;
        font-size:14px;
        font-weight:700;
        color: $theme-body-sub-title-color;
        text-transform: uppercase;
        letter-spacing: 1px;
      }
      ul li {
        display: inline-block;
        text-transform: uppercase;
        margin: 0 20px;
        span {
          display: block;
        }
      }
    }
  }
  video{
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    transform: translateX(-50%) translateY(-50%);
    transition: 1s opacity;
  }
}

@media(max-width:991px){
  .comingsoon {
    .comingsoon-inner{
      img{
        height: 90px;
      }
      h5{
        font-size:30px;
      }
    }
  }
 
}
@media(max-width:575px){ 
  .comingsoon {
    .comingsoon-inner{
      img{
        height: 80px;
      }
      h5{
        font-size:20px;
        margin-top:25px;
        margin-bottom: 15px;
      }
    }
  }
}
/**=====================
    27. Coming Soon CSS Ends
==========================**/
