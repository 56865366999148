/**=====================
   61. base_inputs CSS start
==========================**/
.jkanban-container{
  .note {
    a {
      display: contents;
    }
    color:$light-text;
  }
  footer{
    display:none;
  }
}
.kanban-board-header {
  height: auto;
  width: auto;
  line-height: unset;
  background-color:rgba($primary-color,0.07);
  border-bottom: 1px solid rgba($primary-color,0.1);
  text-align:left;
  &.bg-info {
    &~.kanban-drag {
      background-color: rgba($info-color, 0.3);
    }
  }

  &.bg-warning {
    &~.kanban-drag {
      background-color: rgba($warning-color, 0.3);
    }
  }

  &.bg-success {
    &~.kanban-drag {
      background-color: rgba($success-color, 0.3);
    }
  }
}
.kanban-container {
  display: contents;
}
.kanban-board {
  margin-bottom: 30px;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  border-radius: 8px;
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  overflow: hidden;
  width: calc(100% / 3 - 30px) !important;
  .kanban-drag {
    background-color: rgba($primary-color,0.07);
  }
}
.kanban-item {
  background: transparent;
  padding: 0;
  margin-bottom: 20px;
  p {   
    font-size: 13px;
    color: $light-text;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .list {
    display: inline-block;
    opacity: 0.4;

    li {
      display: inline-block;
      font-size: 12px;

      i {
        margin-right: 10px;
      }
    }
  }

  .kanban-box {
    border: 1px solid $light-gray;
    border-radius: 5px;
    padding: 20px;
    position: relative;
    display: block;
    color: inherit;
    background-color: $white;

    &:hover {
      color: inherit;
    }

    &+.kanban-box {
      margin-top: 20px;
    }

    .badge {
      font-weight: 400;
    }

    h6 {
      font-size: 15px;
      margin-top: 5px;
      font-weight: 500;
    }

    .dropdown {
      display: inline-block;
      right: 10px;
      position: absolute;
      top: 20px;

      .dropdown-item {
        font-size: 13px;
        font-weight: 500;
        padding: 5px 10px;
        text-align: left;
      }

      svg {
        height: 14px;
      }
    }

    .d-flex {
      justify-content: space-between;
    }

    .dropdown-toggle {
      &:after {
        content: none;
      }
    }

    .customers ul li+li {
      margin-left: -10px;
    }

    .date {
      font-size: 11px;      
    }
  }
}
footer{
  margin:0;
  padding:0;
}
.kanban-block{ 
  margin-bottom:-30px; 
}

@media only screen and (max-width: 1300px){
  .kanban-board{
    width:calc(100% / 2 - 30px) !important;
  }
}

@media only screen and (max-width: 1199px) {
  .jkanban-container{
    .btn{
      margin-bottom:10px;
      margin-left:20px;
    }
    .kanban-btn{
      margin-bottom:-10px;
    }
  }
}

@media only screen and (max-width:700px) {
    .kanban-container .kanban-board {
    width:100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .jkanban-container {
    .btn{
      margin-left:unset;
    }
  }
}

@media only screen and (max-width: 480px){
  .jkanban-container{
    .card{
      .card-body{
        padding:15px !important;
      }
    }
    .btn {
      padding:8px 13px;
    }
  }
  .kanban-board {
    .kanban-board-header{
      padding:15px;
    }
    .kanban-drag{
      padding: 15px;
    }
  }
  .kanban-item {
    .list {
      li {
        i{
          margin-right:5px;
        }
      }
    }
  }
  
}

/**=====================
    61. base_inputs CSS ends
==========================**/