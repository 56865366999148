/**===========================
    63. Box-layout CSS start
==============================**/


.box-layout {
  @media only screen and (min-width: 1280px) {   
    .media-size-email{
      display: none;
    }
    .email-wrap{
      .email-right-aside{
          .email-profile{
              .inbox{
                  p{
                    width: calc(100% - 400px);
                  }
              }
          }
      }
  }
    .list-persons .profile-mail .media img{
      height: 50px;
      width: auto !important;
      margin-right: 10px;
    }
    .bookmark-wrap .left-bookmark ul li svg{
      display: none;
    }
    .xl-40{
      flex: 0 0 30%;
    max-width: 30%;
    }
    .xl-60{
      flex: 0 0 70%;
      max-width: 70%;
    }
    .comment-box{
      .xl-100{
        width: 100%;
      }
      .media img {
        margin-right: 25px;     
        height: 60px;      
        width: 60px;      
      }
   }
  .page-wrapper {
    ul {
      &.close_icon {
        > li {
          label {
            padding: 0;
            &:after {
              display: none;
            }
          }
        }
      }
    }
    .bookmark {
      ul {
        margin-right: -1px;
      }
    }
    #batchDelete{
      .jsgrid-grid-header{
        .jsgrid-table{
          tr{
            th{
              .btn{
                padding-left: 20px;
                padding-right: 20px;
              }
            }
          }
        }
      }
    }
    .btn-group-showcase{
      .btn-radio{
        .btn-group{
          .radio{
            input[type="radio"] {
              display: none;
            }
          }
        }
      }
    }
    .media.bitcoin-graph{
      display: block;
      .top-bitcoin {
        display: inline-block;
        vertical-align: middle;
      }
      .media-body{
        margin-top: 30px;
        .bitcoin-content{
          .bitcoin-numbers{
            h6 {
              font-size: 14PX;
            }
          }
          &.text-end{
            text-align: center !important;
          }
        }
      }
    }
    .alert{
      &.inverse{
        p{
          max-width: 238px;
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .page-builder {
      .ge-canvas.ge-layout-desktop {
        margin-top: 50px;
      }
      .ge-addRowGroup {
        margin-bottom: 10px;
      }
    }
    .pricing-wrapper-card {
      padding: 50px 20px;
    }
    .card {
      .blog-box {
        &.blog-grid {
          &.set-min-height {
            min-height: 400px;
          }
        }
      }
    }
    .flot-chart-placeholder {
      &#donut-color-chart-morris-daily {
        min-height: 430px;
      }
    }
    .flot-chart-placeholder {
      &#donut-color-chart-morris {
        min-height: 430px;
      }
    }
    .box-col-12 {
      flex:0 0 100%;
      max-width:100%;
    }
    .box-col-6 {
      flex:0 0 50%;
      max-width: 50%;
    }
    .box-col-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .box-col-7 {
      flex: 0 0 60%;
      max-width: 60%;
    }
    .box-col-5 {
      flex: 0 0 40%;
      max-width: 40%;
    }
    .box-col-8 {
      flex: 0 0 70%;
      max-width: 70%;
    }
    .box-col-4 {
      flex: 0 0 30%;
      max-width: 30%;
    }

    

    // index
    .options-main {
      .option-box {
        h5{
          font-size: 14px;
        }
      }
    }

    .deals-sec{
      order:1;
    }
    .social-review-sec{
      order:1;
    }
    .profile-sec{
      order:2;
    }


    // dashboard 2
    .perform-overview-sec {
      .overview {
        .slick-prev{
          left:25%;
        }
        .slick-next{
          left:30%;
        }
      }
    }



    // dashboard 3
    .project-calendar {
      .card{
        margin-bottom:30px;
      }
    }





    .chat-box {
      .chat-right-aside {
        .chat {
          .chat-header {
            .chat-menu-icons {
              li {
                a {
                  i {
                    font-size: 19px;
                  }
                }
              }
            }
          }
        }
        flex: 0 0 100%;
        max-width: calc(100% - 15px);
        overflow: hidden;
      }
      .toogle-bar {
        display: inline-block;
        margin-right: 0 !important;
      }
    }
    .chat-menu {
      right: 0;
      border-top: 1px solid $light-semi-gray;
      opacity: 0;
      transform: translateY(-30px);
      visibility: hidden;
      top: 81px;
      position: absolute;
      z-index: 9;
      background-color: $white;
      transition: all linear 0.3s;
    }
    .chat-menu.show {
      opacity: 1;
      visibility: visible;
      transform: translateY(0px);
      transition: all linear 0.3s;
      padding-bottom: 25px;
    }
    .ct-10.total-chart{
      .ct-chart-bar{
        .ct-series{
          .ct-bar{
            stroke-width: 23px !important;
          }
        }
      }
    }
    .email-wrap {
      .email-body {
        .email-compose {
          .cke_contents{
            &.cke_reset{
              max-height: 165px;
            }
          }
        }
      }
      .email-right-aside{
        .email-body {
          .inbox{
            height: 644px;
          }
        }
      }
      .email-content {
        .email-top{
          .user-emailid{
            &:after{
              right: -10px;
            }
          }
        }
      }
    }
    .todo {
      .notification-popup{
        right: 320px;
      }
    }
    .touchspin{
      padding: 0 10px;
    }
    .vertical-menu-main {
      width: 1280px;
      margin: 0 auto;
      left: 0;
      right: 0;
      .mega-menu {
        width: 1050px !important;
        max-width: 1050px !important;
        left: -320px !important;
      }
    }
    .comingsoon {
      video{
        min-width: 67%;
        width: 67%;
      }
    }
    .auth-bg-effect {
      .second-effect {
        left: 55%;
      }
    }
    .auth-bg-video{
      video{
        min-width: 67%;
        width: 67%;
      }
    }
      .page-main-header {
        max-width:1280px;
        margin:0 auto;
        left:0;
        right:0;
        transition:$sidebar-transition;
        padding-left:275px;
        width:auto;
        &.close_icon {
          padding-left:0;
          transition: $sidebar-transition;
        }
        .main-header-right {
          margin: 0;
        }
        .vertical-menu-main .mega-menu {
          width: 1050px !important;
          max-width: 1050px !important;
          left: -80px !important;
        }
      }
      &.horizontal_sidebar {
        .page-body-wrapper  {
          .page-sidebar {
            width: 1280px;
            .sidebar-menu {
              .right-arrow {
                left: calc(270px + (1248 - 270) * ((100vw - 320px) / (1920 - 320)));
              }
            }
          }
        }
    }     
      &.horizontal-wrapper {
        .page-main-header {
          margin-left: 0;
          width: 100%;
        }
        .page-body-wrapper {
          &.horizontal-menu {
            header {
              &.main-nav {
                width: 1280px;
                nav{
                  overflow: hidden;
                }
                .main-navbar {
                  #mainnav {
                    width: 1280px;
                    left: 0;
                    // right: 0;
                    // margin: 0 auto;
                    // position: relative;
                  }
                }
              }
            }
          }
          footer{
            width: 100%;
            margin-left: 0;
          }
        }

      }     
      .page-body-wrapper {
        width: 1280px;
        box-shadow: 1px 3px 25px rgba(0, 0, 0, 0.07);
        margin: 0 auto;
        .learning-comment{         
          float: left !important;
        }
        .todo {
          .todo-list-wrapper{
            #todo-list{
              li{
                .task-responsive {
                  min-width: 1087px;
                  overflow: auto;
                }
              }
            }
          }
        }
        .browse{
          div[class^="col-"]{
            &:nth-child(3) {
              margin-top: 30px;
            }
          }
        }
        .active-order-table{
          max-width: 443px;
          overflow: auto;
          table{
            tbody{
              tr{
                td{
                  p {
                    width: 100px;
                  }
                }
              }
            }
          }
        }
        .activity{
          .media{
            .gradient-round{
              &.gradient-line-1{
                &:after {
                  height: 57px;
                  bottom: -64px;
                }
              }
              &.small-line{
                &:after{
                  height: 36px;
                  bottom: -43px;
                }
              }
              &.medium-line{
                &:after{
                  height: 40px;
                  bottom: -48px;
                }
              }
            }
          }
        }
        
        footer {
          width: 1280px;
          margin: 0 auto;
          padding-left: 280px;
          right:0;
          padding-right: 15px;
          margin-left: 255px;
          width: 1280px;
          margin: 0 auto;           
          margin-bottom:40px !important;
          position:fixed;
          left:50%;
          transform:translate(-50%, 0);
          background-color: $white;
          z-index: 2;
        }
        .blockquote-footer {
          margin-left: 0;
          width: 100%;
        }
        .footer-fix{
          padding-left: 280px;
          left: unset;
        }
        .chat-box {
          .chat-history {
            .call-content {
              display: flex;
              align-items: center;
              justify-content: center;
              min-width: 300px;
              > div{
                z-index: 8;
                background-color: rgba(255, 255, 255, 0.75);
                background-blend-mode: overlay;
                width: 100%;
                padding:15px;
                left: 15px;
              }
              button{               
                font-size: 14px;
                margin: 0 auto;
              }
            }
            .call-icons {
              margin-top: 20px;
              margin-bottom: 20px;
              ul {
                li {
                  border: 1px solid #717171;
                  width: 50px;
                  height: 50px;
                  padding: 8px;
                }
              }
            }
            .receiver-img {
              margin-top: 30px;
              margin-bottom: 30px;
              img {
                width: 38%;
              }
            }
            .total-time {
              h2 {
                font-size: 28px;
                color: #717171;
              }
            }
          }
        }
        canvas{
          &#myLineCharts{
            width: 100%;
          }
        }
        .chat-right-aside {         
          flex: 0 0 100%;
          max-width: calc(100% - 15px);
          overflow: hidden;
        }
        .caller-img {
          position: absolute;
          width: 100%;
          max-width: 100%;
          left: 15px;
          img {
            opacity: 0.7;
          }
        }
        .browser-widget{
          img{
            height: 65px;
          }
        }
        .weather-widget-two{
          .bottom-whetherinfo {
            .whether-content{
              top: 39px;
            }
          }
        }
        .custom-card {         
          .card-profile {
            img {
              height: 130px;
              top: -17px;
            }
          }
        }
        .select2{
          width: 901.781px;
        }
      }        
      &.compact-wrapper{
        .page-body-wrapper{
          margin-top:40px !important;                   
          top:80px;
          overflow-y:scroll !important;             
          max-height:calc(100vh - 80px);
          padding-bottom:55px;                         
          header{
            &.main-nav{
              top:40px;
              .main-navbar {
                .nav-menu{
                  height:calc(100vh - 209px);
                }
              }
              &.close_icon{
                width : 0px;
                transform: translate(0px);
                z-index:0;
                display: none;
                visibility: hidden;
                transition: 0.5s;
                ~ footer {
                    padding-left: 0;
                    margin-left: 0;
                }
                ~ .footer-fix {
                    margin-left: auto; 
                    width: 1280px;
                }
                ~ .page-body{
                  margin-left: 0px;
                }
  
  
              }
            }
          }
        }   
        .page-main-header{
          margin-top:40px;
          &.close_icon{
            margin-left : auto;
            padding-left : 0;
            transition: $sidebar-transition;
          }
        }
        &.compact-sidebar{
          .page-main-header{
            margin-left: 320px;
            padding-left: 150px;
            &.close_icon{
              padding-left:0;
            }
          }
          .page-body-wrapper {
            header{
              &.main-nav {
                .main-navbar {                 
                  .nav-menu {                   
                    height: calc(100vh - 193px);
                    margin-top: 20px;
                    margin-bottom: 20px;
                    > li {
                      .nav-submenu{        
                        top: 115px;
                        left: 470px;
                        height: calc(100vh - 190px);
                      }
                    }
                  }
                }
                &.close_icon ~ footer{
                  width: 1280px;
                  left: 50%;
                }
              }
            }
            footer{
              left: 46%;
              padding-left: 0;
              width: calc(1280px - 150px);
            }            
          }
        }
      }

      // file manager 
      .file-content {
        .files {
          .file-box{
            width: calc(50% - 15px);
            margin-bottom: 10px;
            margin-right: 8px;
          }
        }
        .folder {
          .folder-box{
            width: calc(50% - 15px);
            margin-bottom: 10px;
            margin-right: 8px;
          }
        }
      }

      // kanban board
      .kanban-board{
        width: calc(100% / 2 - 30px) !important;
      }

      //project
      .project-box{
        padding:15px;
      }


      //ecommerce pages 
      .grid-options {
        ul {
          li {
            a {
              .line-grid-10,.line-grid-11,.line-grid-12,.line-grid-13,.line-grid-14,.line-grid-15{
                display:none;
              }
            }
          }
        }
      }
      .product-box {
        .product-details {
          padding:20px;
          h4{
            font-size:18px;
          }
        }
      }
      .product-price{
        font-size:16px;
      }
      .product-page-details{
        h3{
          font-size: 20px;
        }
      }
      .product-social{
        li{
          &:nth-child(n+2){
            margin-left:5px;
          }
        }
      }
      .product-page-main{
        .pro-shop{
          .btn{
            padding: 8px 13px;
            font-size: 12px;
          }
        }
      }
      .checkout{
        .checkout-details{
          padding:30px;
        }
      }

      // user pages 
      .edit-profile{
        .profile-title {
          .media{
            display:block;
            .media-body{
              margin-left:unset;
              margin-top:10px;
            }
          } 
        }
      }
    }      
}
}


/**=========================
    63. Box-layout CSS ends
==========================**/