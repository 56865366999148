/**=====================
    63.  Document CSS Start
==========================**/
.document {
  p{
    font-size: 16px;
    margin-bottom: 0;
  }
  .nav-pills{
    margin: 0 20px;
    
    .nav-link{
      padding-left: 30px;
      color: #222222;
      position: relative;
      i{
        position: absolute;
        left: 20px;
        top: 10px;
      }
    }
    .nav-link.active{
      border-radius: 10px;
      background-image: linear-gradient(90deg, #7366ff 0%, #a26cf8 100%);
      color: #fff;
      transition: all 0.5s ease;
      position: relative;
      box-shadow: 0px 0px 12px 0px rgba(115,102,255,0.35);
    }
  }

  .page-wrapper {
    .page-main-header{
      margin-left: 255px;
      width: calc(100% - 255px);
      &.document-header{
        height: 80px;
      }
      .main-header-right {
        padding: 0 0;
      }
      .mobile-sidebar{
        .media-body{
          .switch{
            a{
              svg{
                stroke: #212529;               
              }
            }
          }
        }
      }
      .navbar-collapse{
        .navbar-nav{
          display: flex;
          justify-content: flex-end;
          padding-right: 0;
          width: 100%;
          .nav-item{
            padding-right: 15px;
            &:last-child {
              padding-right: 0;
            }
          }
        }
      }
      svg {
        line {
          color: $white;
        }
      }
      .document-right-nav {
        .navbar {
          .navbar-collapse {
            .navbar-nav {
              .nav-item {
                .nav-link {                  
                  &:hover{
                    text-shadow: none;
                  }
                }
              }
            }
          }
        }
      }
    }
    .page-sidebar {
      box-shadow: 0 0 21px 0 rgba(89,102,122,0.1);
      width: 255px;
      position: fixed;
      background: $white;
      top: 0;
      height: calc(100vh);
      z-index: 9;
      transition: 0.5s;
      color: $black;
      .logo-wrapper{
        box-shadow: -9px 0 20px rgba(89,102,122,0.1);

        img{
          vertical-align: middle;
          border-style: none;
          padding: 23px -7px;
          padding-top: 25px;
          padding-bottom: 25px;
          padding-left: 30px;
        }
      }
      top: 0;
      //padding: 30px ;
    }
    .page-body-wrapper {
      .page-body {
        padding-top: 35px !important;
        min-height: calc(100vh - 81px);
        margin-top: 81px;
        padding: 0 15px;
        position: relative;
        margin-left: 255px
      }
      footer{
        margin-left: 255px;
      }
    }
  }
}
form.form-inline.support-res .nav-link {
  background-color: transparent;
  border: none;
}
.document-content {
  ul.nav-pills {
    overflow-y: scroll;
    max-height: calc(100vh - 180px);
    display: block;

    li {
      h4{
        color: $white;
        padding: 0 15px;
      }
      line-height: 25px;
      .nav-link {
        border-radius: .25rem;
        font-size: 14px;
        display: block;
        text-decoration: none;
        color: #000000;
        font-weight: 500;
        padding: 3px 15px;
        letter-spacing: 1px;
        i {
          margin-top: 5px;
          text-decoration: none;
          color: $black;
          margin-right: 20px;
        }
        &.active {
          background-color: unset !important;
          color: $primary-color;
        }
        p{
          font-size: 16px;
          margin-bottom: 0;
          padding-top: 7px;
          font-weight: 600;
        }
      }
      .show > .nav-link {
        background-color: unset !important;
        color: $primary-color;
      }
    }
  }
  .table {
    thead {
      th {
        width: 50%;
      }
    }
  }
  .set-flex {
    display: flex;
  }
  i {
    &.fa-star {
      color: $btn-search-bg-c;
    }
  }
  .col-9 {
    padding-left: 40px;
    .set-height {
      overflow: scroll;
      overflow-x: hidden;
    }
    .tab-content {
      overflow: auto;
      overflow-x: hidden;
      max-height: calc(100% - 200px);
      position: fixed;
      width: 976px;
      margin-left: 30px;
      -webkit-box-shadow: 0 0 15px 0 rgba(220, 220, 220, 0.4);
      -o-box-shadow: 0 0 15px 0 rgba(220, 220, 220, 0.4);
      box-shadow: 0 0 15px 0 rgba(220, 220, 220, 0.4);
      .section-head {
        font-weight: 600;
        font-size: 32px;
        margin-bottom: 40px;
        padding: 0;
      }
    }
  }
  .card-body.tree-color {
    padding: 0;
  }
  .jstree-default {
    padding: 30px;
    background-color: $dark-card-background;
    box-shadow: 1px 1px 2px 1px rgba(255, 255, 255, 0.08);
    .jstree-anchor {
      color: #a6aaaf;
    }
    .jstree-hovered {
      background-color: $dark-body-background;
      border: 1px solid $dark-border-color;
    }
    .jstree-clicked {
      color: $white !important;
      background: transparent !important;
    }
    .jstree-icon {
      color: #656b78 !important;
    }
  }
  hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  .html-editor {
    color: rgba(255, 255, 255, 0.7);
    background-color: #2b2b2b;
    pre {
      color: rgba(255, 255, 255, 0.7);
      background-color: #000;
    }
  }
  .progress {
    margin-bottom: 10px;
  }
  ul.list-ticked li {
    margin-bottom: 10px;
    padding-left: 1rem;
  }
  &.faq {
    font-size: 18px;
    .btn {
      font-size: 18px;
      font-weight: 600;
      color: $primary-color;
      &.btn-link {
        color: $black;
        &.collapsed {
          color: $primary-color;
        }
      }
    }
    ul {
      border: unset;
      &.nav {
        display: block;
        li .nav-link {
          border-radius: .25rem;
          font-size: 18px;
          display: block;
          padding: 3px 20px 3px 30px;
          text-decoration: none;
          line-height: 30px;
          color: $color-scarpa;
          font-weight: 500;
          border: unset;
          i {
            margin-top: 3px;
            text-decoration: none;
            line-height: 25px;
            color: $primary-color;
            margin-right: 20px;
          }
          &.active {
            color: $primary-color;
            background-color: unset;
            border: unset;
          }
          &:hover {
            border: unset;
          }
        }
      }
    }
  }
  .reviews{
    background-color: $white;
    padding: 30px 0;
    margin-bottom: 30px;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.08);
    h1{
      text-transform: uppercase;
      font-weight: 600;
    }
    h5{
      text-transform: capitalize;
    }
  }
}
/**=====================
    63. Document CSS Ends
==========================**/
