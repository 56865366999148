/**=====================
      86. contacts CSS Start
==========================**/
.alert-contactadd {
  background-color: $white;
  border: 1px solid var(--theme-deafult);
  width: 12%;
  padding: 10px;
}
.updateimg {
  position: absolute;
  left: 15px;
  width: 100px;
  top: 32px;
  line-height: 93px;
  opacity: 0;
}
#right-history {
  top: 81px;
  right: -320px;
  height: 100%;
  position: fixed;
  width: 320px;
  z-index: 9;
  background-color: $white;
  transition: .5s;
  box-shadow: 0 0 9px rgba(191, 191, 191, 0.36);
  h6 {
    span {
      a {
        color: $theme-body-font-color;
      }
    }
  }

  &.show {
    right: 0;
    transition: .3s;
  }
}

.history-details {
  >div {
    padding: 30px 0;
  }
  .media {
    padding: 30px 15px;
    border-top: 1px solid $light-color;
    i {
      padding: 5px;
      background: #fed112;
      color: $white;
      border-radius: 100%;
    }
    h6 {
      margin-bottom: 5px;
    }
  }
}
.delete-contact {
  display: none;
}
.more-data {
  animation: displayTransition 1s;
}
.contact-editform {
  padding: 30px;
  padding-left:unset;
  animation: displayTransition 1s;
  a {
    display: block;
  }
  button {
    margin-top: 30px;
  }
  .select2-container {
    .select2-selection--single {
      height:39px !important;     
      border-color:$light-gray !important;
      background-color: $light-background;
    }
  }
}

#deletemodal {
  .modal-header {
    border-bottom: none;

    .delete-data {
      span {
        svg {
          vertical-align: middle;
          margin-right: 15px;
        }
      }
    }
  }
}

.contact-options {
  li {
    .btn-category {
      line-height:39px;
      border-radius:3px;
      padding-left:40px !important;
      padding:0;          
      color:$primary-color;
      font-weight: 500;
    }
  }
}
.list-persons {
  p {
    font-family:$font-roboto, $font-serif;
  }
  .profile-mail {
    padding: 30px 0;
    .media {
      align-items:center;
      img {
        width:auto;
        height:100px;
      }
      .media-body {
        h5 {
          margin-bottom:5px;
          font-weight:700;
        }
        ul {
          margin-top: 5px;
          li {
            display: inline-block;
            a {
              font-size:13px;
              font-weight:500;
            }
            +li {
              padding-left: 10px;
              margin-left: 5px;
              border-left: 2px solid $light-color;
            }
          }
        }
      }
    }

    .email-general {
      padding-top:20px;
      margin-top:20px;
      border-top:1px solid $light-gray;
      ul {
        padding-right: 20px;
        li {
          color: $dark-gray;
          padding-bottom: 10px;
          margin-bottom: 10px;
          font-weight:500;
          &:last-child {
            padding-bottom: 0;
            margin-bottom: 0;
          }

          >span {
            float: right;
          }
        }
      }

      p {
        span {
          margin-left: 30px;
        }
      }

      .gender {
        margin-top: 30px;
      }
    }
  }
}

.contacts-tabs {
  .nav-pills {
    border-right: 1px solid $light-gray;
    height: 100%;
    .nav-link,
    .show {
      &.active,
      >.nav-link {
        // background-color: rgba($primary-color, 0.05);
        background-color:rgba($primary-color,0.1);
        color: $theme-body-font-color;
         h6{
           color:$primary-color;
         }
      }
    }

    .nav-link {
      color: $theme-body-font-color;
      padding: 0;
      .media {
        .img-50 {
          height: 50px;
        }
      }

      +.nav-link {
        border-top: 1px solid $light-gray;
      }
    }

    .media {
      padding: 20px;
    }
  }

  .tab-content {
    .tab-content-child {
      animation: displayTransition 1s;
    }
  }
}

// responsive
@media only screen and (max-width: 991px) {
  #right-history {
    top: 60px;
  }

  .updateimg {
    top: 21px;
  }

  .list-persons {
    .profile-mail {
      padding: 20px;

      .email-general {
        .gender {
          margin-top: 20px;
        }
      }
    }
  }

  .contact-editform {
    button {
      margin-top: 20px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .contact-editform {
    padding:20px;   
    &.pl-0 {
      padding-left: 20px !important;
    }
  }
  .contacts-tabs{
    .nav-pills{
      border-right: none;
      border-bottom: 1px solid $light-gray;
    }
  }
  .list-persons {
    .profile-mail {
      .email-general {
        ul{
          padding-right:unset;
        }
      }
    }
  }


}

@media only screen and (max-width: 575px) {
  #right-history {
    width: 270px;
  }

  .updateimg {
    top: 16px;
    left: 29px;
  }

  

  .contacts-tabs {
    .media {
      img {       
        &.m-r-20 {
          margin-right: 15px;
        }
      }
    }

    .nav-pills {
      .media {
        padding: 15px;
      }
    }
  }
  

  .list-persons {
    .profile-mail {
      padding: 15px;
      .media {
        img{
          height:80px;
          width:80px !important;
        }
      }
      .email-general {
        .gender {
          margin-top: 15px;
        }
        ul {
          padding-right: unset;
          li{
            font-size:12px;
          }
        }
      }
    }
    .nav-pills {
      .nav-link {
        .media {
          .media-body {
            text-align: left;
          }
        }
      }
    }
  }


  

  .contact-editform {
    padding: 15px;
    button {
      margin-top: 15px;
    }
    &.pl-0 {
      padding-left: 15px !important;
    }
  }

  .contact-editform,
  .contact-options {
    form {
      .row {
        > div {
          .row {
           > div {
              > div {
                margin-bottom: 0;
              }

              +div {
                margin-top: 15px;
              }
            }
          }
        }
      }
    }
  }

  .list-persons {
    .profile-mail {
      .media {
        .media-body {
          h5{
            font-size:16px;
          }
        }
      }
    }
  }
  

  .bookmark-tabcontent {
    .tab-content {
      .card {
        .card-header {
          h5{
            font-size:16px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 360px) {
  .updateimg {
    line-height: 73px;
    width: 80px;
  }

  .list-persons {
    .profile-mail {
      .email-general {
        p {
          span {
            margin-left: 10px;
          }
        }
      }

      .media {
        display: block;

        img {
          height: 80px;
          width: 80px !important;
          margin-bottom: 15px;
        }
      }
    }
  }
}

/**=====================
    86. contacts CSS Ends
==========================**/