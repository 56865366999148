$header-size: 136px;

/**=====================
    05. Header CSS Start
==========================**/
%common {
  border-left-color: $dark-color;
  transition: $sidebar-transition;
}
%for-animated-hover {
  box-shadow: $main-header-shadow;
  transform: translateY(30px);
  opacity: 0;
  visibility: hidden;
  left: 0;
}
%for-animated-hover-box{
  opacity: 1;
  transform: translateY(0px);
  visibility: visible;
  &:before{
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid $white;
    content: "";
    top: -7px;
    position: absolute;
    right: 25px;
    z-index: 2;
  }
  &:after{
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid $light-background;
    content: "";
    top: -7px;
    position: absolute;
    right: 25px;
    z-index: 1;
  }
}
/*======= Page Header css Start ======= */
.page-wrapper{
  .page-main-header{
    position: $main-header-position;
    top: $main-header-top;
    z-index: $main-header-z-index;
    width: 100%;
    display: flex;
    align-items: center;
    transition: $sidebar-transition;
    background-color: $white;
    box-shadow: $main-header-shadow;

    .toggle-sidebar,.onhover-dropdown{
      position: relative;
      &:before{
        content: "";
        position: absolute;
        height: 40px;
        width: 40px;
        background-color: rgba($primary-color, 0.1);
        border-radius: 100%;
        left: -11px;
        z-index: -2;
        top: -8px;
        transform: scale(0);
        transition: all 0.3s ease;
      }
      &:hover{
        &:before{         
          transform: scale(1);        
          transition: all 0.3s ease;
        }
        .notification-box{
           > svg{
            color: $primary-color !important;
            stroke: $primary-color !important;
          }
        }
        > svg{
          color: $primary-color !important;
          stroke: $primary-color !important;
        }
      }
    }
    .onhover-dropdown{
      &:before{
        top: -2px;
        left: -2px;
      }
    }
    &.open{
      margin-left: 0;
      width: 100%;
      transition: $sidebar-transition;
    }
  }
  .page-body-wrapper{
    .page-body{
      min-height: calc(100vh - #{$header-size});
      margin-top: $header-size;
      padding: $page-body-padding;
      position: relative;
      transition: 0.5s;
    }
    .page-header{
      padding-top: $page-header-padding;
      padding-bottom: $page-header-padding;     
      .row{
        align-items:center;
        h3{
          font-size:$page-title-font-size;
          margin-bottom:$page-title-margin-bottom;
          text-transform:$page-title-text-tranform;
          font-weight:700;         
          small{
            display: $page-small-title-display;
            font-size: $page-small-title-font-size;
            margin-bottom: $page-small-title-margin-bottom;
            text-transform: $page-small-title-text-transform;
            color: $theme-body-sub-title-color;
          }
        }
      }
      .breadcrumb{
        background-color: $transparent-color;
        padding: 0;
        margin-top: 5px;
        margin-bottom: 0;
        li{
          font-size:14px;
          letter-spacing:1px;         
          font-weight:500;         
        }
        .breadcrumb-item{
          &+.breadcrumb-item{
            &:before{
              content: $breadcrumb-content;
            }
          }
          a{
            color: $primary-color;
            svg {
              width: 14px;
              height: 14px;
              vertical-align: text-top;
            }
          }
        }
      }
    }
  }
}

/*======= Page Header css ends  ======= */


/**======Main Header css Start ======**/
.page-main-header{
  max-width: 100vw;
  .main-header-right{
    width: 100%;
    display: flex;
    align-items: center;
    padding: $main-header-right-padding;
    position: relative;
    .toggle-sidebar {
      margin-right: 25px;
      cursor: pointer;
      width: auto;
      padding: 0;
      svg {
        width: 18px;        
      }
    }
    .vertical-mobile-sidebar {
      display: none;
    }
    .nav-left{
      i{
        margin-right: 20px;
      }
      input:focus{
        outline: 0 !important;
      }
    }
    .left-menu-header {
      ul {
        li {         
          width:100%;
          .search-bg {
            width: 35px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 10px;
            i {
              color: $semi-dark;
            }
          }
          .search-form {
            display: flex;
            align-items: center;
            border-left: 1px solid $gray-60;            
            padding: 0 10px;
            input {
              padding: 8px 0;
              border:none;
              outline:none;
              background: $transparent-color;
              color: $semi-dark;
              font-weight: 500;
              font-family: $font-roboto;    
              margin-top: 3px;         
              &::placeholder {
                color: $semi-dark;
                font-weight: 400;
                font-family: $font-roboto;
              }
            }
          }
        }
      }
    }
    .nav-right{
      text-align: left;
      padding-left: $main-header-right-nav-right;
      padding-right: $main-header-right-nav-right;
      ul{
        li{
          .mode{
            font-size: 18px;
            transition: all 0.3s ease;
            width: 15px;
            text-align: center;
          }
          svg{
            vertical-align: $main-header-right-nav-icon-align;
            width: $main-header-right-nav-icon-size;
            path{
              color: $main-header-right-nav-icon-color;
            }
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .onhover-show-div {
        li {
          display: block;
          .media{
            a{
              color: $semi-dark;
            }
          }
          a{
            font-size: 15px;           
            letter-spacing: 0.3px;
            font-weight: 600;
          }
        }
      }
      > ul {
        padding: 0 0;
      }
      &.right-menu {
        ul {
          justify-content: flex-end;
          .cart{
            ul{
              height: 331px;
              overflow-y: scroll;
              li{                         
                border-bottom: 1px solid $light-gray;
                padding: 15px 20px;
              div{             
                h6{
                  font-weight: 600;
                  &.qty{
                    display: flex;
                    align-items: center;
                    margin-bottom: 0;
                    input{                    
                      max-width: 60px;
                      margin-left: 15px;
                      padding: 5px 10px;
                      height: 30px;
                    }
                  }
                  &.price{
                    color: $primary-color;
                  }                
                }
                h5{
                  margin-bottom: 0;
                }
                p{
                  margin-bottom: 0;
                }
              }            
              }
            }
          }
        li {
          position: relative;
          .notification {
            position: absolute;
            top: -2px;
            right: -9px;
            padding: 5px 7px;
          }
          .profile-media {
            .media-body {
              margin-left: 15px;
              span {
                color: $semi-dark;
                font-size: 15px;
                letter-spacing: 0.3px;
                font-weight: 600;
              }
              p {
                font-size: 12px;
                line-height: 1;
                color: rgba($theme-body-font-color, 0.7);
                font-weight: 500;
              }
            }
          }
        }
      }
    }
    .icon-user{
      font-size: 16px;
    }
    >ul{
      display: flex;
      align-items: center;
      > li{
        position: relative;
        padding: 6px 10px;
        border-radius: 4px;
        margin-right: 5px;
        .badge {
          padding: 4px 8px;
        }
        span{
          letter-spacing: 0.9px;
          .fa {
            color: $gray-60;
          }
          ul{
            left: inherit;
            right: -10px;
            width: 130px;
            &:before,&:after{
              left: inherit;
              right: 10px;
            }
            li{
              display: block;
              a{
                font-size: 14px;
                color: $dark-color;
                i{
                  margin-left: 10px;
                  font-size: 13px;
                  color: $dark-color;
                }
              }
            }
          }
        }
      }
      .flag-icon{
        font-size: 16px;
      }
    }
    .chat-dropdown {
      width: 330px;
      top: 49px;
      right: 0;
      left: unset;   
      ul{
        max-height: 252px;
        overflow-y: scroll;   
      }
      li {        
        border-bottom: 1px solid #ecf3fa;   
        padding: 20px 20px 0;     
        .media {
          position: relative;
          .status-circle {
            left: 0;
            top: 0;
          }
          .media-body {
            > span {
              font-weight: 500;
            }
            p {
              margin-bottom: 15px;
              color : $dark-gray;
            }
          }
        }
      }
      &:before, &:after {
       right: 10px !important;
       left: unset !important;
     }
   }
   .notification-box {
    position: relative;
    svg {
      animation: tada 1.5s ease infinite;
    } 
    .badge {
      position: absolute;
      right: -5px;
      top: -6px;
      padding: 2px 4px;
      font-size: 12px;
      font-weight: 700;
      font-family: $font-work-sans, $font-serif;
    }
  }
  .notification-dropdown{
    top: 52px;
    width: 300px;
    right: -20px !important;
    left: unset;    
    i{
     position: absolute;
     left: 0;
     top: 4px;
    }
    li {
      padding: 7px 20px;
      .media{
        position: relative;
        img{
          position: absolute;
          left: 0;
          top: 4px;
        }
        .media-body{
          padding-left: 40px;
        }
      }     
      p {
        span {
          color: $light-font;
        }
      }    
    }
    &:before, &:after {
      right: 28px !important;
      left: unset !important;
    }
  }
  .profile-dropdown{
    width: 160px;
    top: 57px;
    left: -110px;
    padding: 10px;
    li{
      padding: 10px !important;
      .btn{
        padding: 0.375rem 1rem;
      }
      &:hover {
        color: $primary-color;
        svg {
          color: $primary-color;
          path {
            color: $primary-color;
          }
        }
      }
      &:last-child {
        border-top: $card-border-color !important;
        padding-top: 10px !important;
      }
      + li {
        padding-top: 0 !important;
      }
      svg {
        width: 16px;
        vertical-align: bottom;
        margin-right: 10px;
      }
    }
  }
}
li{
  display: inline-block;
  position: relative;

}
}
}
.onhover-dropdown{
  cursor: pointer;
  position: relative;
  &:hover{
    .onhover-show-div{
      @extend  %for-animated-hover-box;
    }
  }
}
.onhover-show-div {
  top: 80px;
  position: absolute;
  z-index: 8;
  background-color: $white;
  transition: all linear 0.3s;
  @extend  %for-animated-hover;
  li{
    a{
      svg{
        margin-top: 0 !important;
        path, line{
          color: $theme-body-font-color !important;
        }
      }
    }
  }
}
/**======Main Header css Ends ======**/

/** ===== Vertical Menu css Start=====**/
.vertical-menu-main{
  background: white;
  position: fixed;
  top: 88px;
  z-index: 1;
  width: 100%
}
.vertical-menu-mt{
  margin-top: 148px !important;
  margin-left: 0 !important;
  .custom-card{
    .card-header{
      img{
        margin-top: -146px !important;
      }
    }
  }
}
.vertical-menu-main{
  #main-nav{
    #main-menu{
      li{
        a{
          svg{
            width: 15px;
            height: 15px;
            margin-right: 8px;
            stroke-width: 2.5;
          }
        }
      }
    }
  }
  .mega-menu {
    width: 1500px !important;
    max-width: 1500px !important;
    padding: 0;
    left:-120px !important;
    top: 65px !important;
  }
}
/** ===== Vertical Menu css Ends=====**/

/*=======Mega menu css start=======*/
.mega-menu {
  perspective: inherit;
  position: static !important;
  padding-top: 5px;
  .default-according{
    .card{
      .btn-link{
        font-weight: 500;
        color: gray;
      }
      .card-body{
        font-size: 12px;
      }
    }
  }
  .onhover-show-div{
    width: 1500px;
  }
  .card-body{
    padding: 20px;
  }
  .title {
    color: $theme-body-font-color;
    font-weight: 600;
    border-bottom: 1px solid $light-semi-gray;
    padding-bottom: 15px;
    margin-bottom: 15px;
    text-transform: uppercase;
  }
  div {
    >div {
      a {
        margin-bottom: 0px;
        display: inline-block;
        color: $theme-body-sub-title-color;
      }
    }
  }
  .custom-nav-img {
    position: static;
    .desk {
      line-height: 25px;
      font-size: 14px;
      color: $theme-body-sub-title-color;
    }
  }
  .list-unstyled {
    div {
      a{
        padding: 8px 35px 8px 0;
        transition: $sidebar-transition;
        &:hover{
          padding: 8px 35px 8px 10px;
          transition: $sidebar-transition;
        }
      }
    }
  }
  .galleria div {
    margin-bottom: 5px;
    &:hover {
      transform: translateY(4px) scale(1.04);
    }
  }
  .galleria-list .galleria > div {
    width: 25%;
    font-size: 12px;
    float: left;
    color: $black;
    transition: ease-in 0.3s;
    > a {
      display: block;
      margin: 5px 0 10px;
      padding: 0 0!important;
    }
    img {
      border-radius: 3px;
      max-width: 100%;
    }
    .username {
      font-size: 12px;
      color: $theme-body-font-color;
      margin-bottom: 5px;
      small {
        display: block;
        font-size: 11px;
        color: $theme-body-sub-title-color;
        margin-top: 3px;
      }
    }
  }
  .drilldown {
    overflow: hidden;
    width: 100%;
    padding: 0;
    transform: translate3d(0, 0, 0);
    a{
      width: 100%;
      &:hover{
        background-color: transparent;
        color:$primary-color;
      }
    }
  }
  .drilldown-sub { display: none; }
  .drilldown-back { font-weight: bold; }
}
/*=======Mega menu css end=======*/


// responsive header
@media only screen and (max-width: 1366px) {
  // .profile-dropdown {
  //   &.onhover-show-div {
  //     right: 0;
  //     left: unset !important;
  //     &:before, &:after {
  //       right: 10px !important;
  //       left: unset !important;
  //     }
  //   }
  // }
}
@media only screen and (max-width: 991px) {
  .page-main-header {
    .main-header-right {
      .nav-right {
        .notification-dropdown {
          top: 46px;
        }
      }
      .left-menu-header {
        ul {
          li{
            width:260px;
          }
        }
      }
    }
  }
  
}

@media only screen and (max-width: 575px){
  .page-main-header {
    .main-header-right {
      .left-menu-header {
        ul {
          li{
            width: auto;
          }
        }
      }
    }
  }
} 
/**=====================
    05. Header CSS Ends
==========================**/
