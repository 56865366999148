/**=====================
    33.  Ecommerce  CSS Start
==========================**/

/**====== Product Start ======**/

.toggle-data {
  cursor: pointer;
}
.product-sidebar {
  .filter-section {
    .card {
      .card-header {
        padding: 16px 30px;
        border-radius: 5px;
        h6 {
          position: relative;
          .pull-right {
            i {
              position: absolute;
              top: 4px;
              left: 0;
              font-size: 10px;
              width: 100%;
              height: 10px;
              text-align: right;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
.product-wrapper {
  .product-sidebar {
    .filter-section {
      .card {
        .left-filter {          
          opacity: 0;
          visibility: hidden;
          height: 0;
          z-index: 3;
          line-height:1;
          transition: 0.3s;
          .product-filter {
            .irs-with-grid {
              margin-bottom: 15px;
            }
          }
        }
      }
    }
  }
  .product-grid {
    .product-wrapper-grid {
      margin-left: 0;
      transition: 0.3s;
    }
  }
  &.sidebaron {
    .product-sidebar {
      .filter-section {
        .card {
          .left-filter {
            z-index: 3;
            opacity: 1;
            visibility: visible;
            position: absolute;
            width: 100%;
            background-color: $white;
            top: 80px;
            height: auto;
            border: 1px solid $light-semi-gray;
          }
        }
      }
    }    
  }
}
.d-none-productlist {
  display: none;
  svg {
    vertical-align: middle;
    cursor: pointer;
  }
}
.product-wrapper-grid {
  &.list-view {
    .product-box {
      display: flex;
      align-items: center;
      > .product-img{
        width: 30%;
      }
      .product-details {
        text-align: left;
      }
    }
    .ribbon{
      display: none;
    }    
    .col-sm-6{
      flex: 0 0 33.33%;
      max-width: 33.33%;     
    }
  }

}
.slider-product {
  padding: 15px 0 ;
  border-top: 1px solid $light-color;
  border-bottom: 1px solid $light-color;
  margin-bottom: 15px ;
}
.products-total {
  display: flex;
  align-items: center; 
}
.grid-options {
  height: 33px;
  margin-left: 10px;
  ul {
    li {
      a {
        position: relative;
        .line-grid {
          position: absolute;
          width: 4px;
          height: 15px;
          top: 9px;
        }
        .line-grid-1 {
          left: 12px;
        }
        .line-grid-2 {
          left: 18px;
        }
        .line-grid-3 {
          left: 36px;
        }
        .line-grid-4 {
          left: 42px;
        }
        .line-grid-5 {
          left: 48px;
        }
        .line-grid-6 {
          left: 66px;
        }
        .line-grid-7 {
          left: 72px;
        }
        .line-grid-8 {
          left: 78px;
        }
        .line-grid-9 {
          left: 84px;
        }
        .line-grid-10 {
          left: 103px;
        }
        .line-grid-11 {
          left: 109px;
        }
        .line-grid-12 {
          left: 115px;
        }
        .line-grid-13 {
          left: 121px;
        }
        .line-grid-14 {
          left: 127px;
        }
        .line-grid-15 {
          left: 133px;
        }
      }
    }
  }
}
.square-product-setting {
  height: 36px;
  vertical-align: middle;
  a {
    color: $theme-body-font-color;
  }
  .icon-grid {
    padding: 7px;
    background-color: $white;
    display: flex;
    align-items: center;   
    svg {
      width: 20px;
      height: 20px;
    }
  }
}
.product-filter{
  .banner-product{
    margin-top: 30px;
    width:100%;
  }
  .checkbox-animated {
    label{
      color:$light-text;
    }
  }
  h6 {
    margin-bottom: 15px;
  }
  .color-selector {
    line-height: 0.9;
    ul {
      li {
        display: inline-block;
        width: 20px;
        height: 20px;
        border:1px solid #444;
        border-radius: 100%;
        cursor: pointer;
        &.white {
          background-color: $white;
        }
        &.gray {
          background-color: $theme-body-sub-title-color;
        }
        &.black {
          background-color: $black;
        }
        &.orange {
          background-color: #ffb17a;
        }
        &.green {
          background-color: #6fb866;
        }
        &.pink {
          background-color: pink;
        }
        &.yellow {
          background-color: #f2f896;
        }
        &.blue {
          background-color: #63b4f2;
        }
        &.red {
          background-color: #ff647f;
        }
      }
    }
  }
  &.new-products {
    margin-top: 20px;
    button {
      width: auto;
    }
    .owl-theme {
      .owl-nav {
        margin-top: 0;
        position: absolute;
        top: -46px;
        right: 0;
        button {
          &:focus {
            outline: $transparent-color;
          }
          span {
            font-size: 20px;
            &:focus {
              outline-color: $transparent-color;
            }
          }
        }
      }
      .owl-nav [class*='owl-']{
        &:hover {
          background-color: $transparent-color;
          color: inherit;
        }
      }
      .owl-item {
        .item {
          .product-box {
            .media{
             display: flex;
             align-items: center; 
            }
            .product-img{
              width:40%;       
              margin-right:20px;                   
            }
            .product-details {
              padding: 25px 0;
              display: flex;
              align-items: center;             
              ul{
                li{
                  display: inline-block;
                }
              }
            }
            +.product-box {
              margin-top: 15px;
            }
          }
        }
      }
    }
  }
}
.select2-drpdwn-product {
  .form-control {
    border:  none;
    box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
    margin-bottom: 10px;
    height: 36px;
  }
}
.feature-products {
  form {
    .form-group {
      position: relative;
      input{
        margin-bottom: 15px;
        border: none;       
        height: 50px;
        padding-left:30px;        
      }
      i{
        position: absolute;
        top: 17px;
        right: 20px;
        color: $dark-gray;
      }
    }
  }
  .featured-sec{
    text-align:right;
    .select-options{
      margin-left:10px;
    }
  }
}
.product-box{
  .product-details {
    padding: 25px;   
    h4{
      font-weight: 700;
      color: $theme-body-font-color;
      font-size: 20px;
      margin-bottom: 5px;
    }
    p{
      color: $light-text;           
      margin-bottom: 0;
    }
    h6{
      color: $theme-body-font-color;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
    span{
      color: $dark-gray;
    }
    .addcart-btn{
      .btn{
        font-weight:600;
        &:nth-child(n+2){
          margin-left:15px;
        }
      }
    }   
  }
  .modal {
    .modal-header {
      .product-box {
        .product-details {
          padding: 25px 0;
          h6 {
            text-transform: capitalize;
          }
          .product-price {           
            margin-bottom: 10px;
          }
          .product-view {
            padding:20px 0;
            border-top:1px solid $light-gray;
            border-bottom:1px solid $light-gray;
          }
          .product-size{
            margin: 20px 0;
            ul{
              li{
                display:inline-block;
                background-color:rgba($primary-color,0.1) ;
                button{
                  color:$primary-color ;
                  width:35px;
                  height:35px;
                  font-size:16px;
                  padding:0;
                  font-weight:500;
                  background-color: transparent !important;
                  border-color: transparent !important;
                  border-radius:0;
                }
                &:nth-child(n+2){
                  margin-left:15px;
                }
                &.active{
                  button{
                    background-color: $primary-color !important;
                    color: $white;
                    border-radius:0;
                  }
                }
              }
            }
          }
          .product-qnty {
            fieldset {
              margin-bottom: 20px;
              .input-group {
                width: 150px;
                .btn {
                  padding: 5px 12px;
                }
                .btn-primary {
                  background-color: $primary-color !important;
                  border: 1px solid $primary-color !important;
                }
              }
            }
          }
        }
      }
    }
  }
  .product-img{
    position: relative;
    .product-hover{
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      display:flex;
      align-items:center;
      justify-content: center;
      background-color: rgba($black,0.5);
      opacity:0;
      border-radius: 100%;
      transform: scale(0);
      transition: all 0.3s ease;
      ul{
        li{
          display: inline-block;
          box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.08);
          padding: 9px 14px;
          background-color: $white;
          font-size: 18px;
          border-radius: 100%;
          line-height: 1.6;
          height: 45px;
          width: 45px;
          margin: 0 3px;
          a{
            color: $theme-body-font-color;
          }
          .btn {
            padding: 0;
          }
          &:hover{
            background-color:$primary-color;
            .btn, i {
              color: $white;
            }
            transition: all 0.3s ease;
          }
        }
      }
    }
  }
  &:hover{
    .product-hover{
      opacity: 1;
      border-radius: 0%;
      transform: scale(1);
      transition: all 0.3s ease;
    }
  }
}
.product-page-main{
  padding: 30px;
  .owl-item{
    .item{
      border: 1px solid $light-color;
      border-radius: 5px;
    }
    &.current{
      .item{
        border: 1px solid $primary-color;
      }
    }
  }
  .product-slider{
    margin-bottom:20px;
  }
  p{
    font-size:15px;
    color:$light-text;
  }
  .nav-link{
    text-transform: uppercase;
    font-weight:600;
  }
  .pro-slide-right {
    margin-bottom: -20px;
    .slick-slide{
      margin-bottom:20px;
    }
    img{
      width: auto;
      height: 140px;
    }
  }
  .pro-group{
    padding-top:15px;
    padding-bottom:15px;
    &:nth-child(n+2){
      border-top: 1px solid $light-semi-gray;
    }
    .pro-shop{
      .btn{
        &:nth-child(n+2){
          margin-left:15px;
        }
      }
    }
  }
}
.product-page-details{
  display:flex;
  align-items:center;
  justify-content:space-between;
  h3{
    font-weight:600;
    font-size:24px;
    margin-bottom:5px;
  }
  h4{
    color: $theme-body-font-color;
    text-transform: uppercase;
    font-size: 18px;
  }
  
  span{
    padding-left: 15px;
  }
}
.br-theme-fontawesome-stars{
  .br-widget {
    a,a.br-selected:after,a.br-active:after {
      color: #ffa800 !important;
      font: normal normal normal 14px/1 FontAwesome;
    }
  }
}
.product-price{
  font-size:18px;
  font-weight:700;
  color:$primary-color;
  del{
    color: $semi-dark;  
    margin-left: 15px;
    font-weight:400;
    font-size:14px;
  }
}
.product-color{
  margin-top:10px;  
  margin-bottom:10px;
  line-height:1;
  li{
    display: inline-block;
    border-radius: 100%;
    height:20px;
    width:20px;    
  }
}
.product-social{
  li {
    a{     
      font-size:15px;
      border:1px solid $light-gray;
      border-radius: 100%;
      height:35px;
      width:35px;
      display: inline-block;
      text-align: center;
      line-height: 2.3;     
      transition: all 0.3s ease;     
      &:hover{
        background-color: rgba($primary-color,0.8);
        color:$white;
      }
    }
    &:nth-child(n+2){
      margin-left:10px;
    }
  }
}
.filter-block {
  h4{
    margin-bottom:20px;
    font-weight:700;
  }
  ul {
    li {
      .form-check{
        margin-bottom: unset;
        label{
          margin-bottom:unset;
          color: $light-text;
        }
      }
      &:nth-child(n+2){
        margin-top:10px;
      }
    }
  }
}
ul.pro-services {
  li {
    svg{
      color:$primary-color;
      margin-right:15px;
    }
    h5{
      font-weight:600;
      font-size:16px;
      margin-bottom:5px;
    }  
    p{
      font-size:14px;
    } 
    &:nth-child(n+2){
      margin-top:20px;
    }
  }
}

.pro-filter-sec{
    display: flex;
    justify-content: space-between;
  .product-sidebar{
    width:375px;
    min-width:375px;
    margin-right:30px;
  }
  .product-search{
    width:100%;
  }
}
ul.rating-pro{
  li{
    display:inline-block;
  }
}
.list-products {
  .product-table {
    table {
      th{
        min-width:150px;
      }
    }
  }
}


/**====== Product  Ends ======**/
.payment-opt{
  li{
    display: inline-block;
    img{
      height: 20px;
      margin: 15px 15px 0 0;
    }
  }
}


.support-ticket{
  .pro-gress{
    .total-num {
      margin-bottom:0;
      font-weight:600;
    }
    .progress-showcase{
      margin-top:30px;
    }
    span{
      font-weight:500;
      color:$light-font;
    }
    ul{
      li{
        font-weight:600;
        color:$light-font;
        font-size:12px;
        span{
          i{
            margin-left:10px;
          }
        }
      }
    }
  } 
}







/**=====================
    33. Ecommerce  CSS End
==========================**/
@media screen and (max-width: 1660px) and (min-width: 1200px) {
  .xl-cs-65 {
    max-width: 65%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 65%;
    flex: 0 0 65%;
  }
  .xl-cs-35 {
    max-width: 35%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 35%;
    flex: 0 0 35%;
    order: -1;
  }
  .owl-carousel .owl-item img {
    max-width: 420px;
    margin: 0 auto;
  }
}


@media screen and (max-width:1470px){
  .pro-filter-sec {
    .product-sidebar{
      width: 360px;
      min-width: 360px;
    }
  }
}


@media screen and (max-width:1366px){
  .product-price{
    font-size:14px;
  }
  .pro-filter-sec {
    .product-sidebar{
      width: 275px;
      min-width: 275px;
    }
  }
}

@media screen and (max-width:991px){
  .product-page-details {
    h3{
      font-size:22px;
    }
  }
  .product-box{
    .modal{
      .modal-header{
        .product-box{
          .product-details{
            padding-left:15px;
            padding-right: 15px;
            padding-top: 25px;
            padding-bottom: 0;
            .product-view{
              padding-top:15px;
              padding-bottom:15px;
            }
            .product-size{
              margin-top: 15px;
              margin-bottom: 15px;
            }
          }
        }
      }
    }
  }
  


}


@media screen and (max-width:767px){
  .product-page-details {
    h3{
      font-size:18px;
    }
  }
  .product-page-main{
    .border-tab.nav-tabs .nav-item{
      width:auto !important;
    }    
  }
  .pro-group{
    .pro-shop{
      .btn{
        padding:8px 15px;
        font-size:13px;
      }
    }
  }
}


@media screen and (max-width:575px){
  .product-color {
    li{
      width:15px;
      height:15px;
    }
  }
}

@media screen and (max-width:420px){
  .product-page-main .border-tab.nav-tabs .nav-item{
    width: 100% !important;
  }
  .border-tab{
    &.nav-tabs{
      display:block;
    }
  }
  .product-box {
    .modal {
      .modal-header {
        .product-box {
          .product-details{
            padding:15px;
            padding-bottom: unset;
          }
        }
      }
    }
    .product-details {     
      .addcart-btn {
        .btn{
          padding:8px 10px;
          font-size:12px;
          &:nth-child(n+2){
            margin-left:10px;
          }
        }
      }
    }
  }
  .product-page-main{
    .pro-group{
      .pro-shop{
        .btn{
          padding:8px 15px;
          display:block;
          width:fit-content;
          &:nth-child(n+2){
            margin-left:0;
            margin-top:10px;
          }
        }
      }
    }
  }
}